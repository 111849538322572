import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/icon'
import Tooltip from 'components/tooltip'

import * as styles from './zebra.module.css'

const TdWithTooltip = ({ text = '', tooltip = '' }) => (
  <React.Fragment>
    <span className="text-black-90">{text}</span>
    <Tooltip title={tooltip}>
      <button className="flex items-center pl-1">
        <Icon className="text-black-90" fontSize="20px" name="info" />
      </button>
    </Tooltip>
  </React.Fragment>
)
const Zebra = ({ className = '', list = [] }) => {
  const hasTooltip = (td) => Boolean(typeof td === 'object' && td.tooltip)

  return (
    <div className={`${styles.zebraTable} ${className}`}>
      {list.map((row, rowIndex) => (
        <div key={rowIndex} className={styles.tr}>
          {row.map((cell, cellIndex) => (
            <div key={cellIndex} className={styles.td}>
              {cell === 'check' ? (
                <Icon className="text-black-90" fontSize="20px" name="check" />
              ) : cell === 'close' ? (
                <Icon className="text-black-40" fontSize="20px" name="close" />
              ) : hasTooltip(cell) ? (
                <TdWithTooltip text={cell.data} tooltip={cell.tooltip} />
              ) : (
                cell
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

TdWithTooltip.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string
}
Zebra.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array
}

export default Zebra
