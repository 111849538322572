import { useTranslation } from 'gatsby-plugin-react-i18next'

const streamBasicPlanFeature = [
  'pricingPage.streamBasicPlan.features.0',
  'pricingPage.streamBasicPlan.features.2',
  'pricingPage.streamBasicPlan.features.3',
  'pricingPage.streamBasicPlan.features.4',
  'pricingPage.streamBasicPlan.features.5',
  'pricingPage.streamBasicPlan.features.6',
  'pricingPage.streamBasicPlan.features.1'
]
const webPlanFeature = ['pricingPage.webPlan.features.0', 'pricingPage.webPlan.features.1']
const webPlanList = [
  {
    key: 'developer',
    link: '/sign-up',
    name: 'pricingPage.webPlan.customerTypes.0',
    price: 0,
    period: 'pricingPage.webPlan.perMonth'
  },
  {
    key: 'business',
    link: '/sign-up',
    name: 'pricingPage.webPlan.customerTypes.1',
    price: 500,
    period: 'pricingPage.webPlan.perMonth'
  },
  {
    key: 'enterprise',
    link: '/contact-us',
    name: 'pricingPage.webPlan.customerTypes.2',
    price: null,
    period: ''
  }
]
const webComparisonTable = () => {
  const { t } = useTranslation()

  return [
    [t('pricingPage.webTable.body.row1'), 20, 50, t('pricingPage.webTable.body.custom')],
    [t('pricingPage.webTable.body.row2'), '100k', '1mil', t('pricingPage.webTable.body.custom')],
    [
      t('pricingPage.webTable.body.row3'),
      t('pricingPage.webTable.body.toEnable', {
        price: '$100 USD',
        value: t('pricingPage.webTable.body.quotaLimit', { value: '10k' })
      }),
      '100k',
      t('pricingPage.webTable.body.custom')
    ],
    [t('pricingPage.webTable.body.row4'), 6, 20, t('pricingPage.webTable.body.custom')],
    [
      t('pricingPage.webTable.body.row5'),
      t('pricingPage.webTable.body.requestNumber', { value: '5GB + 200k' }),
      t('pricingPage.webTable.body.requestNumber', { value: '1TB + 10mil' }),
      t('pricingPage.webTable.body.custom')
    ],
    [
      {
        data: t('pricingPage.webTable.body.row6'),
        tooltip: t('pricingPage.webTable.body.tooltip6')
      },
      'close',
      'check',
      'check'
    ],
    [
      {
        data: t('pricingPage.webTable.body.row7'),
        tooltip: t('pricingPage.webTable.body.tooltip7')
      },
      'close',
      'close',
      'check'
    ],
    [t('pricingPage.webTable.body.row8'), 5, 20, t('pricingPage.webTable.body.custom')],
    [t('pricingPage.webTable.body.row9'), '3 FQDNs', '5 FQDNs', t('pricingPage.webTable.body.custom')],
    [
      t('pricingPage.webTable.body.row10'),
      t('pricingPage.webTable.body.ruleNumber', { value: 3 }),
      t('pricingPage.webTable.body.ruleNumber', { value: 10 }),
      t('pricingPage.webTable.body.custom')
    ],
    [
      t('pricingPage.webTable.body.row11'),
      t('pricingPage.webTable.body.toEnable', {
        price: '$100 USD',
        value: t('pricingPage.webTable.body.ruleNumber', { value: 3 })
      }),
      t('pricingPage.webTable.body.ruleNumber', { value: 5 }),
      t('pricingPage.webTable.body.custom')
    ]
  ]
}
const servicesComparisonTable = () => {
  const { t } = useTranslation()

  return [
    [t('pricingPage.serviceTable.body.row1'), 'check', 'check', 'check'],
    [
      t('pricingPage.serviceTable.body.row2'),
      t('pricingPage.serviceTable.body.byUsageOrPrice', { price: '$200 USD', usage: '10%' })
    ],
    [
      t('pricingPage.serviceTable.body.row3'),
      t('pricingPage.serviceTable.body.byUsageOrPrice', { price: '$1,000 USD', usage: '18%' })
    ],
    [
      t('pricingPage.serviceTable.body.row4'),
      t('pricingPage.serviceTable.body.byUsageOrPrice', { price: '$5,000 USD', usage: '25%' })
    ],
    [t('pricingPage.serviceTable.body.row5'), 'close', 'close', 'check'],
    [t('pricingPage.serviceTable.body.row6'), 'close', 'close', 'check'],
    [t('pricingPage.serviceTable.body.row7'), 'check', 'check', 'check'],
    [t('pricingPage.serviceTable.body.row8'), 'close', 'close', 'check']
  ]
}

export { servicesComparisonTable, streamBasicPlanFeature, webComparisonTable, webPlanFeature, webPlanList }
