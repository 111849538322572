import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import PropTypes from 'prop-types'

import Seo from 'components/seo'
import SimpleSlider from 'components/carousel/simple'
import SimpleAccordion from 'components/accordion/simple'
import ZebraTable from 'components/table/zebra'
// import LangSwitcher from 'components/langSwitcher/popover'
import Icon from 'components/icon'

import business from '/static/images/pricing/business_plan.png'
import developer from '/static/images/pricing/developer_plan.png'
import enterprise from '/static/images/pricing/enterprise_plan.png'
import { allCdnList } from '/static/data/cdnBrand.static'
import faqList from '/static/data/faq.static'
import { servicesComparisonTable, webComparisonTable, webPlanFeature, webPlanList } from '/static/data/pricing.static'

import * as pricingStyles from 'components/pricing.module.css'

const BRAND_SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  speed: 9000,
  autoplaySpeed: 1000,
  cssEase: 'linear'
}

const PLAN_WEB = {
  DEVELOPER: 'developer',
  BUSINESS: 'business',
  ENTERPRISE: 'enterprise'
}

const WebPlanButtonGroup = ({ activeButton = '', handleClick }) => {
  const { t } = useTranslation()
  const buttonStyle = (button) => {
    return activeButton === button ? pricingStyles.active : ''
  }

  return (
    <div className={pricingStyles.roundedButtonGroup}>
      {webPlanList.map((plan) => {
        const planName = plan.key.toUpperCase()

        return (
          <button
            key={plan.key}
            onClick={handleClick}
            value={PLAN_WEB[planName]}
            className={buttonStyle(PLAN_WEB[planName])}
          >
            {t(plan.name)}
          </button>
        )
      })}
    </div>
  )
}
const WebPlanCard = ({ price, period, link = '' }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="h4-content-text text-black-90 text-center mb-6">
        {typeof price === 'number' ? (
          <React.Fragment>
            <span>USD {price}</span>
          </React.Fragment>
        ) : (
          <span className="h4-content-text text-black-90">{t('pricingPage.webPlan.customPrice')}</span>
        )}
        <span>{t(period)}</span>
      </div>
      <div className="flex justify-center">
        {typeof price === 'number' ? (
          <Link
            to={link}
            className="btn-orange block h4-content-text text-black-100 py-2 px-3 rounded text-center min-w-[178px]"
          >
            {t('pricingPage.button.getStart')}
          </Link>
        ) : (
          <Link
            to={link}
            className="btn-orange block h4-content-text text-black-100 py-2 px-3 rounded text-center min-w-[178px]"
          >
            {t('pricingPage.button.contactUs')}
          </Link>
        )}
      </div>
    </div>
  )
}

const PricingPage = () => {
  const WEB_COMPARISON_TABLE = webComparisonTable()
  const SERVICE_COMPARISON_TABLE = servicesComparisonTable()
  const currentYear = new Date().getFullYear()

  const { t } = useTranslation()
  const { screenXL } = useBreakpoint()
  const [isSticky, setIsSticky] = useState(false)
  const [activeWebPlan, setActiveWebPlan] = useState(null)
  const [webTableMap, setWebTableMap] = useState({ all: WEB_COMPARISON_TABLE })
  const [serviceTableMap, setServiceTableMap] = useState({ all: SERVICE_COMPARISON_TABLE })

  const clickWebPlan = (event) => {
    setActiveWebPlan(event.target.value)
  }
  const tableCellFallback = (row = [], cellIndex = 0) => {
    // find correct value if it is a merged cell
    while (cellIndex > 0 && typeof row[cellIndex] === 'undefined') {
      cellIndex--
    }
    return [row[0], row[cellIndex]]
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 1240) {
        setIsSticky(false)
        return
      }
      const triggerHeight = 380 // 可自定義觸發高度
      const triggerHeightEnd = 1576.12
      setIsSticky(window.scrollY > triggerHeight && window.scrollY < triggerHeightEnd)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    const planIndexMapping = {
      [PLAN_WEB.DEVELOPER]: 1,
      [PLAN_WEB.BUSINESS]: 2,
      [PLAN_WEB.ENTERPRISE]: 3
    }

    if (!webTableMap[activeWebPlan]) {
      setWebTableMap({
        ...webTableMap,
        [activeWebPlan]: WEB_COMPARISON_TABLE.map((row) => tableCellFallback(row, planIndexMapping[activeWebPlan]))
      })
    }
    if (!serviceTableMap[activeWebPlan]) {
      setServiceTableMap({
        ...serviceTableMap,
        [activeWebPlan]: SERVICE_COMPARISON_TABLE.map((row) => tableCellFallback(row, planIndexMapping[activeWebPlan]))
      })
    }
  }, [activeWebPlan])

  useEffect(() => {
    setActiveWebPlan(screenXL ? 'all' : PLAN_WEB.DEVELOPER)
  }, [screenXL])

  return (
    <React.Fragment>
      <Seo
        seo={{
          metaTitle: `Mlytics Pricing - Compare Free, Business and Enterprise Plan`,
          metaDescription: `Mlytics offers different tiers of subscription plans and flexible pay-as-you-go model tailored to your needs. Subscribe today to accelerate your application's load time with our advanced platform.`,
          metaUrl: 'https://www.mlytics.com/pricing',
          shareImage: {
            imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
            imageWidth: 1200,
            imageHeight: 630,
            imageType: 'image/jpeg'
          }
        }}
      />
      <div className="min-h-100vh w-full">
        <div className="pt-3 pb-2.5 fixed w-full bg-white h-[60px] z-50">
          <div className="px-9 md:px-[86px] max-w-[1440px] mx-auto">
            <div className="w-full flex items-center justify-between">
              <Link to="/" className="shrink-0 text-xl font-medium">
                <img
                  alt="Mlytics"
                  src="https://www.mlytics.com/wp-content/uploads/2021/11/logo_Mlytics_regular.svg"
                  height="75"
                  width="140"
                />
              </Link>
              {/* <div>
                <LangSwitcher className="flex h-9 items-center" />
              </div> */}
            </div>
          </div>
        </div>
        <section className="pt-[80px] pb-[60px] px-9 md:px-[86px]">
          <h1 className="h1-page-title text-black flex flex-col items-center justify-center gap-2.5">
            <Trans i18nKey="pricingPage.title">
              <span className="block"></span>
            </Trans>
          </h1>
        </section>
        <section className="px-4 pb-[60px] base:px-[86px]">
          {/* mobile plan content */}
          <div className="xl:hidden mt-7 mx-auto max-w-[680px]">
            <div className="w-full flex items-center justify-center gap-4 md:gap-10 mb-[44px]">
              <div className="h3-card-title text-black-100">{`${t('pricingPage.webPlan.cardTitle')} >`}</div>
              <div className="flex flex-col py-5 px-8 bg-black-80 rounded-2xl">
                {webPlanFeature.map((feature) => (
                  <div key={feature} className="flex items-center gap-2 h4-content-text text-white">
                    <Icon className="mr-2 text-white" fontSize="20px" name="check" />
                    <span>
                      <Trans i18nKey={feature}>
                        <span className="h4-content-text" />
                      </Trans>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <WebPlanButtonGroup activeButton={activeWebPlan} handleClick={clickWebPlan} />
            <div className="p-8">
              {webPlanList.map((plan) => {
                return plan.key === activeWebPlan ? (
                  <WebPlanCard key={plan.key} link={plan.link} price={plan.price} period={plan.period} />
                ) : null
              })}
            </div>
          </div>
          {/* desktop plan content */}
          <div className="hidden xl:block">
            <div className="w-full flex items-center justify-center gap-10 mb-[44px]">
              <div className="h3-card-title text-black-100">{`${t('pricingPage.webPlan.cardTitle')} >`}</div>
              <div className="flex flex-col py-5 px-8 bg-black-80 rounded-2xl">
                {webPlanFeature.map((feature) => (
                  <div key={feature} className="flex items-center gap-2 h4-content-text text-white">
                    <Icon className="mr-2 text-white" fontSize="20px" name="check" />
                    <span>
                      <Trans i18nKey={feature}>
                        <span className="h4-content-text" />
                      </Trans>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                position: isSticky ? 'fixed' : 'static',
                backgroundColor: isSticky ? '#FFFFFF' : 'transparent',
                top: '60px',
                right: '0',
                zIndex: 10,
                width: '100%'
              }}
              className="flex items-center justify-center mx-auto xl:w-[1200px] transition-all duration-500 ease-in-out"
            >
              <div className="bg-transparent flex-1 max-w-[279px] h-full"></div>
              {webPlanList.map((plan) => (
                <div
                  key={plan.key}
                  className="flex-1 flex flex-col border-b items-center border-black-80 pt-4 px-6 pb-6 gap-6 max-w-[307px]"
                >
                  {/* <h3 className="capitalize mb-4 text-center text-lg">{t(plan.name)}</h3> */}
                  <div className="w-[200px] overflow-hidden object-cover">
                    <img
                      src={plan.key === 'developer' ? developer : plan.key === 'business' ? business : enterprise}
                      alt={plan.key}
                    />
                  </div>
                  <WebPlanCard link={plan.link} price={plan.price} period={plan.period} />
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              paddingTop: isSticky ? '213px' : '0'
            }}
            className="mx-auto py-5 xl:w-[1200px]"
          >
            <h2 className="py-2 px-2.5 h5-subtext-bold text-black-50">{t('pricingPage.webTable.title')}</h2>
            <div className="overflow-scroll ">
              <ZebraTable list={webTableMap[activeWebPlan]} />
            </div>
          </div>
          <div className="mx-auto xl:w-[1200px]">
            <h2 className="py-2 px-2.5 h5-subtext-bold text-black-50">{t('pricingPage.serviceTable.title')}</h2>
            <div className="overflow-scroll">
              <ZebraTable list={serviceTableMap[activeWebPlan]} />
            </div>
          </div>
        </section>
        <section className="px-4 py-[60px] md:px-[86px] flex justify-center">
          <div className="flex flex-col gap-10 max-w-[1200px]">
            <h2 className="h2-block-title text-black-100 text-center">{t('pricingPage.section1.title')}</h2>
            <div className="grid grid-cols-2 gap-5 justify-items-center xl:hidden">
              {allCdnList.map((item) => (
                <img key={item.alt} src={item.src} alt={item.alt} height="85" width="170" />
              ))}
            </div>
            <div className="hidden xl:block">
              <SimpleSlider className="slick-bg-white-gradient-x" settings={BRAND_SLIDER_SETTINGS}>
                {allCdnList.map((item) => (
                  <div key={item.alt}>
                    <img src={item.src} alt={item.alt} height="80" width="160" />
                  </div>
                ))}
              </SimpleSlider>
            </div>
          </div>
        </section>
        <section className="px-4 py-[60px] md:px-[86px] flex justify-center">
          <div className="flex flex-col gap-10 max-w-[800px]">
            <h2 className="h2-block-title text-black-100 text-center">{t('pricingPage.section2.title')}</h2>
            <div>
              <SimpleAccordion
                defaultExpandIndex={0}
                list={faqList}
                borderColor="#B9B9B9"
                iconStyle="plus"
                iconClassName="text-black-90"
                h3ClassName="text-white h4-content-text !text-black-90"
                spanClassName="text-black-70 h5-subtext-medium block pb-3"
                cannotBeShrinked
              />
            </div>
          </div>
        </section>
        <footer className="mt-10 px-9 md:px-[86px] py-5 border-t border-black-20">
          <p className="h6-tiny-text-regular text-black-60">
            {t('footer.companyAnnouncementText', { year: currentYear })}
          </p>
        </footer>
      </div>
    </React.Fragment>
  )
}

WebPlanButtonGroup.propTypes = {
  activeButton: PropTypes.string,
  handleClick: PropTypes.func
}
WebPlanCard.propTypes = {
  price: PropTypes.number,
  period: PropTypes.string,
  link: PropTypes.string
}

export default PricingPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
